<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")
.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp
Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
export default {
  name: "EditCards",
  components: { Footer, Navbar, BackHelp },
  data() {
    return {
      ContactList: [
        {
          title: "4.2 How to edit/remove saved cards?",
          description: [
            {
              text: "1. To edit/remove saved bank cards,  go to the “Account” at the bottom tab and tap the “Payment method” ",
              imgType: true,
              img: "400.Account – empty – 1.png",
            },
            {
              text: "2. You will see the “Payment methods” page which features all the bank cards you have saved previously.",
              imgType: true,
              img: "451.Payment method-1.png",
            },
            {
              text: "3. You can either delete the card or set your non-primary card as the primary card by tapping the 3 dots icon on the top right.",
              imgType: true,
              img: "452.Payment method-2.png",
            },
          ],
        },
      ],
    };
  },
};
</script>
